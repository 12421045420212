export const CalculateDiscount = (productRates, discountAmount) => {
  const totalRate = productRates.reduce((sum, rate) => sum + rate, 0);
  const discountRatios = productRates.map((rate) => rate / totalRate);
  const discountApplied = [];
  for (let i = 0; i < productRates.length; i++) {
    const discount = Math.round(discountAmount * discountRatios[i]);
    discountApplied.push(discount);
  }
  const totalDiscountApplied = discountApplied.reduce(
    (sum, discount) => sum + discount,
    0
  );
  if (totalDiscountApplied !== discountAmount) {
    const difference = discountAmount - totalDiscountApplied;
    let maxDecimalIndex = -1;
    let maxDecimal = -1;
    for (let i = 0; i < discountApplied.length; i++) {
      const decimalPart = discountApplied[i] % 1;
      if (decimalPart > maxDecimal) {
        maxDecimal = decimalPart;
        maxDecimalIndex = i;
      }
    }
    discountApplied[maxDecimalIndex] += difference;
  }
  return discountApplied;
};