export const TransactionTypeId = {
    BALANCE_ENQUIRY: 306,
    VALIDATE_REDEEM: 302,
    REDEEM: 302
}

export const InputType = 1;

export const NumberOfCards = 1;

export const CollectionType = {
    SELLER: 'SELLER',
    FYND: 'FYND'
}

export const TagType = {
    MOP: 'mop'
}

export const DiscountMessage = 'Giftcard';
export const UserType = {
    AUTHENTICATED: 'authenticated',
    ANONYMOUS: 'anonymous'
}