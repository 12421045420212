<template>
  <div class="apply-card">
    <div class="dis-flex flex-dir-col">
      <div class="head">Card Number :</div>
      <div class="value">{{ cardNumber }}</div>
    </div>
    <div class="dis-flex flex-dir-col">
      <div class="head">
        {{ buttonType == "APPLY" ? "Available" : "Applied" }} Balance :
      </div>
      <div class="value">₹ {{ balance }}</div>
    </div>
    <button @click="handleApply" :disabled="disable || isApplyValid ">
      {{ buttonType }}
    </button>
  </div>
</template>
<script>
import { getCartInfo } from '../../services/cart.service';
import { getCartId, isThisBuyNow } from '../../utils';
// import { getLocalStorageValue } from "../../utils/storage.utils";

export default {
  name: "apply-balance",
  components: {},
  props: {
    cardNumber: {},
    balance: {},
    buttonType: {
      type: String,
      default: "APPLY",
    },
    apiCall:{
      default:false
    }
  },
  data() {
    return {
      cartData: null,
      cartValue: null,
      disable:false
    };
  },
  watch: {
    apiCall() {
      if (this.apiCall) {
        this.disable = true
      } else {
        this.disable = false
      }
    },
  },
  async beforeMount() {
    let cartId = getCartId();
    let cartInfo = await getCartInfo(cartId,isThisBuyNow());
    this.cartValue = cartInfo?.data?.data?.breakup_values?.raw?.total;
  },
  methods: {
    handleApply() {
      this.disable = true;
      let value = this.buttonType !== "APPLY" ? true : false;
      this.$emit("handleApply", value);
    },
  },
  computed: {
    /**
     * Computed method to check if type is button and supposed to show
     */
    isApplyValid() {
      return this.balance <= 0 && this.cartValue <= 0;
    },
  },
};
</script>
<style>
.apply-card {
  padding: 24px;
  margin: 13px;
  background: #fff;
  border-radius: 7px;
}
.apply-card .head {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #6d6d6e;
  margin: 0.5rem;
  margin-left: 0;
}

.apply-card .value {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #4d4d4e;
  margin: 0.25rem;
  margin-left: 0;
}

.dis-flex {
  display: flex;
}

.flex-dir-col {
  flex-direction: column;
}
.apply-card button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 24px;
  gap: 10px;
  border: 1px solid var(--buttonPrimary, #1b6163);
  background: var(--buttonPrimary, #1b6163);
  width: 100%;
  cursor: pointer;
  color: white;
  font-weight: 500;
  border: none;
  margin-top: 2rem;
  border-radius: 4px;
}
</style>
