import axios from 'axios';
import { getBaseUrl } from "./../utils/url.utils";

const url = getBaseUrl();

export const getRedeemSettings = () => {
    return axios.get(
        `https://${url}/ext/qwikcilver-wallet/application/v1/settings/redeem`
    );
}

export const getAccountSettings = () => {
    return axios.get(
        `https://${url}/ext/qwikcilver-wallet/application/v1/settings/account`
    );
}