export const getCartId = () => {
    let params = new URL(window.location).searchParams;
    let cart_id = params.get("id") || params.get("cart_id") || JSON.parse(localStorage.getItem("m_usercart"))?.value?.id;
    return cart_id
} 
export const isThisBuyNow = ()=>{
    let params = new URL(window.location).searchParams;
    let buy_now = params.get("buy_now");
    return buy_now;
}
let isZeroCartValueActive = false;
export const setZeroValueCheckout = (value) =>{
    isZeroCartValueActive = value;
}
export const getZeroValueCheckout = () =>{
    return isZeroCartValueActive
}