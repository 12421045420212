<template>
  <div v-if="showWidget" :class="disable ? 'disable-giftcard' : ''">
    <div :key="cartRedemptionRef" v-if="giftCardRedemptionActive">
      <fdk-cart class="product__actions" ref="cart">
        <template slot-scope="cart">
          <button style="display: none" @click="handleReload(cart)">
            Reload
          </button>
        </template>
      </fdk-cart>
      <div class="redeem-dialog">
        <div class="vertical-center">
          <div class="card-img">
            <img src="./../../assets/card.svg" alt="close" />
          </div>
          <div class="redeem-content">
            <div class="redeem-heading">
              {{ !totalBalance ? `Have a Gift Card ?` : `` }}
            </div>
            <div class="redeem-text">
              {{
                totalBalance
                  ? `₹ ${totalBalance} Card Points Applied `
                  : "Redeem Points To Save More"
              }}
            </div>
          </div>
        </div>
        <div @click="openDialog" class="click-apply-button">
          <div>
            {{ !(totalBalance > 0) ? `APPLY GIFT CARD` : `REMOVE GIFT CARD` }}
          </div>
        </div>
      </div>

      <Modal
        title="Redeem Card Points"
        @close="closeDialog"
        v-if="show"
        :show="show"
      >
        <template slot="body">
          <CheckBalance
            :errorMessage="errorMessage"
            @handleCheck="handleCheck"
            v-show="!checked"
            :apiCall="apiCall"
          />
          <ApplyBalance
            :cardNumber="cardNumber"
            :balance="buttonText == 'APPLY' ? fetchedBalance : totalBalance"
            @handleApply="handleApply"
            v-show="checked"
            :buttonType="buttonText"
            :apiCall="apiCall"
          />
        </template>
      </Modal>
      <div :v-if="applied && totalBalance" class="success-popup">
        <SuccessModal
          :show="applied"
          :balanceValue="totalBalance"
          @closeSucessModal="handleCloseSuccess"
        />
      </div>
    </div>
    <div v-else></div>
  </div>
</template>
<script>
import CheckBalance from "./checkBalance.vue";
import ApplyBalance from "./applyBalance.vue";
import SuccessModal from "./successModal.vue";
import Modal from "./../modal/Model.vue";
// import ToasterBox from "../toasters/toaster.vue";

import {
  authorize,
  balanceEnquiry,
  redeemCard,
  checkRedeem,
  cancelRedeem,
} from "../../services/qwikcilver-core.service";
// import { getSalt } from "./../../services/encryption.service";
import {
  getCartId,
  getZeroValueCheckout,
  isThisBuyNow,
  setZeroValueCheckout,
} from "./../../utils";

import {
  createDynamicTag,
  deleteDynamicTag,
  getCartInfo,
} from "../../services/cart.service";
import { getRedeemSettings } from "../../services/settings.service";

import {
  TransactionTypeId,
  InputType,
  NumberOfCards,
  TagType,
} from "../../constants/cart.constants";
import { CalculateDiscount } from "./../../utils/discount-calculation.utils";
import { getEnvs } from "./../../services/env.service";
export default {
  name: "balance-redeemption",
  components: {
    CheckBalance,
    ApplyBalance,
    SuccessModal,
    Modal,
  },
  data() {
    return {
      cardNumber: "",
      cardPin: "",
      checked: false,
      applied: false,
      show: false,
      buttonText: "APPLY",
      totalBalance: 0,
      articleList: [],
      userData: null,
      cartData: null,
      existingRedeemData: null,
      ifExistingRedeemExist: false,
      fetchedBalance: null,
      cartRedemptionRef: 0,
      redeemAmount: 0,
      giftCardRedemptionActive: false,
      rowID: "",
      disable: true,
      toastMessage: "",
      errorMessage: false,
      toastType: "",
      showMsg: false,
      apiCall: false,
      uid: false,
      user: null,
      cartValue: 0,
    };
  },

  async mounted() {
    window.FPI.event.on("cart.update", this.handleUpdateCartEvent);
    window.FPI.event.on("cart.remove", this.handleUpdateCartEvent);
    window.FPI.state.user.subscribe((data) => {
      if (data && data.user) {
        this.user = data.user;
      }
    });
    if (window.location.pathname == "/cart/checkout") {
      this.disable = true;
    }
    let params = new URL(window.location).searchParams;
    let cart_id = getCartId();
    let cartInfo = await getCartInfo(cart_id, isThisBuyNow());
    // console.log("cartInfo", cartInfo.data.data.breakup_values)
    let cartData = cartInfo?.data?.data;
    if (cartInfo?.data?.status == "success" && cartInfo?.data?.data) {
      this.cartValue = cartData?.breakup_values?.raw?.total || 0;
    }
    if (
      window.location.pathname == "/cart/checkout" &&
      params.get("cart_id") &&
      params.get("address_id") &&
      params.get("billing_address_id") &&
      params.get("reviewed") &&
      this.cartValue
    ) {
      this.disable = false;
    }
    if (
      window.location.pathname == "/cart/checkout" &&
      (!params.get("cart_id") ||
        !params.get("address_id") ||
        !params.get("billing_address_id") ||
        !params.get("reviewed"))
    ) {
      this.disable = true;
    }
    if (window.location.pathname == "/cart/payment-info" && this.cartValue) {
      this.disable = false;
    }
    await this.isGiftCardRedemptionActive();

    this.existingRedeemData = await checkRedeem(cart_id);

    if (
      this.existingRedeemData?.data?.status == "success" &&
      this.existingRedeemData?.data?.data
    ) {
      this.ifExistingRedeemExist = true;
      this.totalBalance = this.existingRedeemData?.data?.data?.amount;
      console.log("check 1", this.existingRedeemData?.data?.data?.amount);
      this.cardNumber = this.existingRedeemData?.data?.data?.card_number;
      this.rowID = this.existingRedeemData?.data?.data?.row_id;
      this.uid = this.existingRedeemData?.data?.data?.uid;
      this.buttonText = "REMOVE";
      this.checked = true;
    }
    let response = await getEnvs();
    setZeroValueCheckout(response?.data?.ZERO_VALUE_CHECKOUT);
  },

  watch: {
    show(val) {
      if (this.show) {
        document.body.classList.add("prevent-scroll");
      } else {
        document.body.classList.remove("prevent-scroll");
      }
      if (val && this.buttonText == "APPLY") {
        this.totalBalance = 0;
      } else {
        this.totalBalance = this.redeemAmount;
        console.log("checkl 2", this.redeemAmount);
      }

      if (this.ifExistingRedeemExist && this.buttonText === "REMOVE") {
        this.totalBalance = this.existingRedeemData?.data?.data?.amount;
        console.log("check 3", this.existingRedeemData?.data?.data?.amount);
        console.log("check existing redeem data 3", this.existingRedeemData);
      }
    },
    $route: function (to) {
      let queryParam = Object.assign({}, to.query);
      if (
        queryParam.billing_address_id &&
        queryParam.cart_id &&
        queryParam.address_id &&
        queryParam.reviewed &&
        window.location.pathname == "/cart/checkout" &&
        this.cartValue
      ) {
        this.disable = false;
      } else {
        this.disable = true;
      }
      if (window.location.pathname == "/cart/payment-info" && this.cartValue) {
        this.disable = false;
      }
    },
  },
  methods: {
    /**
     * cart event
     */

    async handleUpdateCartEvent() {
      console.log("removing on cart update event call");
      this.handleApply(true);
    },

    /**
     * Method to trigger show dialog
     */
    openDialog() {
      if (!this.disable) {
        this.show = true;
      }
    },

    /**
     * Method to trigger close dialog
     */
    closeDialog() {
      if (this.apiCall) {
        return;
      }
      this.show = false;
    },

    forceRerender() {
      this.cartRedemptionRef += 1;
    },

    /**
     * Method to trigger balance enquiry
     * @param {*} value : Values received from checkBalance.vue on CardNumber and CardPin applied.
     */
    async handleCheck(value) {
      try {
        this.cardNumber = value.cardNumber;
        this.cardPin = value.cardPin;
        if (value.cardNumber && value.cardPin) {
          this.balanceCheck();
        } else {
          let msg = !value.cardNumber ? "Card Number" : "Card Pin";
          this.errorMessage = `Please provide ${msg} `;
        }
      } catch (error) {
        console.error(error?.message);
      }
    },

    /**
     *
     * @param {*}
     */
    async handleApply(isRemove) {
      try {
        if (!isRemove) {
          await this.applyGiftCard();
        } else {
          await this.cancelRedeem();
          this.closeDialog();
          this.forceRerender();
          this.$forceUpdate();
          this.totalBalance = 0;
        }
      } catch (error) {
        console.log("error", error);
        console.error(error?.message);
      }
    },

    /**
     * Method to handle close and success flags
     */
    async handleCloseSuccess() {
      this.applied = false;
      this.show = false;
      this.buttonText = "REMOVE";
      await this.handleReload();
    },

    /**
     * Method to initiate authorize service which takes care of generating AuthToken
     */
    authorizeSeller: async function () {
      try {
        await authorize();
      } catch (error) {
        console.error(error?.message);
      }
    },

    /**
     * Applying the qwikcilver giftcard with redeem
     */
    async applyGiftCard() {
      try {
        let cart_id = getCartId();
        let cartInfo = await getCartInfo(cart_id, isThisBuyNow());
        console.log("cartInfo", cartInfo);
        let cartData = cartInfo?.data?.data;
        if (cartInfo?.data?.status == "success" && cartInfo?.data?.data) {
          this.apiCall = true;
          let cartValue = cartData?.breakup_values?.raw?.total;
          console.log("cartValue", cartValue);
          this.redeemAmount =
            cartValue < this.fetchedBalance
              ? getZeroValueCheckout()
                ? cartValue
                : cartValue - 1
              : this.fetchedBalance;
          let reqBody = {
            TransactionTypeId: TransactionTypeId.REDEEM,
            InputType: InputType,
            Cards: [
              {
                CardNumber: this.cardNumber,
                CardPin: this.cardPin,
                Amount: this.redeemAmount,
              },
            ],
            numberOfCards: NumberOfCards,
            BillAmount: this.redeemAmount,
          };
          let response = await redeemCard({ cart_id, uid: this.uid }, reqBody);

          this.rowID = response?.data?.data.row_id;

          this.collectArticles(cartData?.items);
          setTimeout(() => (this.totalBalance = this.redeemAmount), 200);
          const tagResponse = await this.createDynamicTag(this.uid);
          this.apiCall = false;
          if (tagResponse.data.success) {
            this.applied = true;
            // setTimeout(() => {
            //   this.applied = false
            //   // this.show = false
            // }, 1000);
          }
        }
      } catch (error) {
        this.apiCall = false;
        console.error(error?.message);
      }
    },

    async balanceCheck() {
      try {
        this.errorMessage = false;
        this.apiCall = true;
        let reqBody = {
          TransactionTypeId: TransactionTypeId.BALANCE_ENQUIRY,
          InputType: InputType,
          Cards: [
            {
              CardNumber: this.cardNumber,
              CardPin: this.cardPin,
            },
          ],
        };
        //// add encryption here  before we send to backend

        // let keyResponse = await getSalt();

        // const publicKey = new Uint8Array(
        //   [...atob(keyResponse.data.data.publicKey)].map((char) =>
        //     char.charCodeAt(0)
        //   )
        // );
        // let encryptedPayload = await this.encryptPayload(reqBody, publicKey);
        let cart_id = getCartId();
        const response = await balanceEnquiry(
          {
            data: reqBody,
          },
          cart_id
        );
        this.fetchedBalance = response?.data?.data?.balance;
        this.uid = response?.data?.data?.uid;
        this.apiCall = false;
        if (!response?.data?.data?.balance) {
          this.errorMessage = `Card Balance is ${response?.data?.data?.balance}`;
        } else {
          if (this.fetchedBalance > 0) {
            this.checked = true;
          }
          this.toastMessage = "Successfully fetched the balance";
          this.toastType = "success";
          this.showMsg = true;
          this.errorMessage = false;
        }
      } catch (error) {
        this.apiCall = false;
        this.errorMessage =
          error?.response?.data?.data?.message == "Validation Failed."
            ? "Validation Failed !"
            : error?.response?.data?.data?.message;
      }
    },
    async encryptPayload(payload, publicKey) {
      try {
        // Convert the payload to ArrayBuffer
        const encoder = new TextEncoder();
        const data = encoder.encode(payload);

        // Import the public key
        const publicKeyObject = await crypto.subtle.importKey(
          "spki",
          publicKey,
          {
            name: "RSA-OAEP",
            hash: "SHA-256",
          },
          false,
          ["encrypt"]
        );

        // Encrypt the data
        const encryptedData = await crypto.subtle.encrypt(
          {
            name: "RSA-OAEP",
          },
          publicKeyObject,
          data
        );

        // Convert encrypted data to base64
        const encryptedDataBase64 = btoa(
          String.fromCharCode(...new Uint8Array(encryptedData))
        );
        return encryptedDataBase64;
      } catch (error) {
        console.error("Encryption error:", error);
        throw error;
      }
    },
    async createDynamicTag(uid) {
      try {
        let tagBody = {
          cart_id: getCartId(),
        };
        const tagResponse = await createDynamicTag(tagBody, {
          uid,
          buynow: isThisBuyNow(),
        });
        if (tagResponse.data.data.success) {
          // if the cart response is true then we will close the dialogue
          this.closeDialog();
        }
        return tagResponse.data;
      } catch (error) {
        console.error(error?.message);
        return false;
      }
    },
    async handleReload() {
      if (this.$refs["cart"]) {
        await this.$refs["cart"].getCart();
      } else {
        window.location.reload();
      }
    },
    collectArticles(cartItems) {
      try {
        let articleListRef = [];
        let articleValues = cartItems?.map((item) => {
          return item?.price?.base?.selling;
        });
        let discounts = CalculateDiscount(articleValues, this.redeemAmount);
        cartItems?.forEach((item, i) => {
          let article = {
            article_id: item?.article?.uid,
            value: parseInt(discounts[i] * 100) / 100,
            code: "giftcard",
            type: TagType.MOP,
            meta: {},
          };
          articleListRef.push(article);
        });
        this.articleList = articleListRef;
      } catch (error) {
        console.error(error?.message);
      }
    },

    async cancelRedeem() {
      try {
        this.apiCall = true;
        let cart_id = getCartId();
        await cancelRedeem(this.rowID);
        this.totalBalance = 0;
        this.checked = false;
        this.show = false;
        this.applied = false;
        this.buttonText = "APPLY";
        this.redeemAmount = 0;
        this.rowID = false;
        await deleteDynamicTag(cart_id, this.uid);
        this.apiCall = false;
        this.uid = false;
        this.handleReload();
      } catch (error) {
        this.apiCall = false;
        console.error(error?.message);
      }
    },
    async isGiftCardRedemptionActive() {
      try {
        const response = await getRedeemSettings();
        if (response?.data?.data?.active) {
          this.giftCardRedemptionActive = true;
          await this.authorizeSeller();
        } else {
          this.giftCardRedemptionActive = false;
        }
      } catch (error) {
        console.error("ERROR_ISGIFTCARDREDEMPTIONACTIVE", error?.message);
      }
    },
  },
  computed: {
    showWidget: function () {
      const buynow = isThisBuyNow();
      return !!this.user && !buynow;
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");

.success-popup .modal-header {
  display: none;
}

.success-popup .modal-mask {
  justify-content: center;
  align-items: center;
}

.success-popup .modal-container {
  width: 312px;
  min-height: max-content;
  /* padding: 24px; */
}

.redeem-dialog {
  background: #fff;
  justify-content: space-between;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
  gap: 16px;
  width: 100%;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}

.redeem-content .redeem-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
  color: #000000;
}
.card-img {
  margin-right: 10px;
}
.redeem-content .redeem-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  text-transform: capitalize;
  color: #4d4d4e;
}
.vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.prevent-scroll {
  overflow: hidden !important;
}
.click-apply-button {
  color: var(--linkColor, #ff3f6c);
  cursor: pointer;
  font-weight: 700;
  font-size: 12px;
  cursor: pointer;
}
.disable-giftcard {
  opacity: 0.5;
  pointer-events: none;
}
</style>
